<template>
	<div class="customerHome customerOrderAssessmentRead">
		<div class="chuck">
			<div class="title">
				查看评估
			</div>
			<Form style="margin-top: 20px;" ref="assessmentForm" :model="assessmentForm" inline>
				<FormItem style='width: 100%;' prop="evaluationOpinion">
					<!-- 					<Input :disabled="true" maxlength="1000" show-word-limit :rows="15" type="textarea"
						placeholder="评估意见" v-model="assessmentForm.evaluationOpinion"></Input> -->
					<div class="article" v-html="assessmentForm.evaluationOpinion"></div>
				</FormItem>
			</Form>
			<Button class="btn" type="primary" @click="back()">确定</Button>
			<!-- 复制按钮 -->
			<Button v-copy='copyText' type="text">复制文字</Button>
		</div>
	</div>
</template>

<script>
	import {
		data_require_detail
	} from "@/api/customerApi.js";
	export default {
		name: 'customerOrderAssessmentRead',
		data() {
			return {
				assessmentForm: {}
			}
		},
		mounted() {
			this.init()
		},
		computed: {
			requireUid() {
				return this.$route.query.requireUid
			},
			copyText() {
				// let html = this.assessmentForm.evaluationOpinion ?? ''
				let html = this.assessmentForm.evaluationOpinion?this.assessmentForm.evaluationOpinion:''

				html = html.replace(/<\/p>/g,"\r\n");
				html = html.replace(/<\/div>/g,"\r\n");
				html = html.replace(/<\/table>/g,"\r\n");
				html = html.replace(/<\/h[0-9]>/g,"\r\n");

				// html = html.replace(/(\n)/g, "");
				// html = html.replace(/(\t)/g, "");
				// html = html.replace(/(\r)/g, "");
				html = html.replace(/<\/?[^>]*>/g, "");
				// html = html.replace(/(\s)/g, "");
				html = html.replace(/&nbsp;/ig, " ");
				html = html.replace(/&amp;/ig,'&')
				return html
			}
		},
		methods: {
			init() {
				this.getAssessment()
			},
			async getAssessment() {
				let data = await data_require_detail({
					requireUid: this.requireUid
				})
				this.assessmentForm = data
			},
			back() {
				this.$router.push({
					path: '/customerorder/customerorder',
					query: {
						tab: 2
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import "@/style/customerorder.scss";

	.btn {
		display: inline-block;
		width: 140px;
		height: 40px;
		position: relative;
	}

	.chuck {
		min-height: calc(100vh - 120px);
	}

	.article {
		width: 100%;
		min-height: 650px;
		overflow: auto;
		background-color: #efefef;
		padding: 10px;
	}
</style>
